import React from 'react'
import '../css/rate.css'
import { formatCOPNumber, formatCOPNumberInput, formatDollar } from '../functions'
const Rates = ({ dollars=0, sendFee=0, transferFee=0, amountToSend=0, amountToTransfer=0}) => {
    if (!dollars || parseFloat(dollars) === 0 || dollars.toString()=== '$') {
        return
    }
    return (
    <div className='flex flex-col box py-5 px-2 mt-6 w-full'>
        <div className='flex flex-row justify-between mb-2'>    
            <p className='lead'>Dólares a pagar</p>
            <p className='lead'>{dollars}</p>
        </div>
        <div className='flex flex-row justify-between mb-2'>    
        <p className='labelSec'>Comisión envío</p>
        <p className='labelSec'>{formatCOPNumber(sendFee.toString())}</p>
        </div>
       {/*  <div className='flex flex-row justify-between mb-2'>    
        <p className='labelSec'>{'Comisión \n transferencia'}</p>
            <p className='labelSec'>600</p>
        </div> */}
        <div className='flex flex-row justify-between mb-2'>    
        <p className='labelSec'>Dólares a enviar</p>
            <p className='labelSec'>{formatDollar(amountToSend.toString())}</p>
        </div>
        <div className='flex flex-row justify-between'>    
        <p className='lead'>Pesos a transferir</p>
            <p className='lead'>{formatCOPNumber(amountToTransfer)}</p>
        </div>
      
    </div>
  )
}

export default Rates