import React from 'react'
import Loading from './Loading'
import '../css/btn.css'
const Button = ({onClick =()=>{}, disabled=false, loading=false, label='hola', className=''}) => {
  return (
    <button className ={`${disabled ? 'btn-disabled': 'btn'} ${className} flex justify-center align-center`} disabled={(disabled || loading)} onClick={() =>{onClick()}}>
        {/* <div className='h-7 '>
            {
                loading && <Loading/>
            }
        </div> */}
            <p className='text-center'>{loading ?  'Cargando...' :label}</p>
    </button>
  )
}

export default Button