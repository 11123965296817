import React, { useEffect, useState } from 'react'
import '../css/step.css'
import InputAmount from './InputAmount'
import USA from '../assets/Usa.png'
import CO from '../assets/Co.png'
import InputAmountSecundary from './InputAmountSecundary'
import Rates from './Rates'
import axios from 'axios'
import { formatCOPNumber, formatDollar, getDigits } from '../functions'
import Button from './Button'

const Step1 = ({ currentStep=null, setCurrentStep=()=>{}, steps=[],setAmount_=()=>{}, setEquivalent_=()=>{}, setAmountCobru=()=>{}, amountCobru=0, setErrorStep1=()=>{}, errorStep1=''}) => {
  const [amount, setAmount] = useState('')
  const [trm, setTrm] = useState(0)
  const [equivalent, setEquivalent] = useState(0)
  const [payedAmount, setPayedAmount] = useState(0)
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTRM()
  }, [])
  useEffect(() => {
    if (amount, trm) {
      const amount_  = getDigits(amount)
      getAmountForCobru(amount_)
      getEquivalent(parseFloat(amount_)-(parseFloat(amount_)*.16))
      /* getAmountForCobru(amount_)
      getEquivalent(parseFloat(amount_)-(parseFloat(amount_)*.16)) */
      
    }
  }, [amount, trm])


  
  const getTRM = async  () => {
    try {
      const res = await axios.post("/api/swap", {amount: 0});
      console.log(res?.data);
      if (res?.data?.data?.result?.amount_trm) {
        setTrm(res?.data?.data?.result?.amount_trm)
      }
      //console.log(res);
    } catch (error) {
      setTrm(0)
    }
  }
  const getEquivalent_ = async  (amount) => {
    setLoading(true)
    try {
      const res = await axios.post("/api/swap", {amount});
      console.log('eq: ',res?.data?.data?.result?.amount_swap);
      if (res?.data?.data?.result?.amount_swap) {
        setEquivalent(res?.data?.data?.result?.amount_swap)
        setPayedAmount(res?.data?.data?.result?.payed_amount)
        setEquivalent_(res?.data?.data?.result?.amount_swap)
        setLoading(false)

      }
      setLoading(false)

      //console.log(res);
    } catch (error) {
      setEquivalent(0)
      setLoading(false)

    }
  }
  const getEquivalent = async  (amount) => {
    setLoading(true)
    try {
      setPayedAmount(amount)
      setEquivalent(amount*trm)
      setEquivalent_(amount*trm)
      setLoading(false)

      //console.log(res);
    } catch (error) {
      setEquivalent(0)
      setLoading(false)

    }
  }
  const getAmountForCobru = async (amount_) => {
    setLoading(true)
    try {
      setAmountCobru(parseFloat(amount_)*trm)
    } catch (error) {
      
    }
  }

  const getAmountForCobru_ = async  (amount) => {
    setLoading(true)
    try {
      const res = await axios.post("/api/swap", {amount});
      console.log('eq: ',res?.data?.data?.result?.amount_swap);
      if (res?.data?.data?.result?.amount_swap) {
        setAmountCobru(res?.data?.data?.result?.amount_swap)
        setLoading(false)

      }
      setLoading(false)

      //console.log(res);
    } catch (error) {
      setEquivalent(0)
      setLoading(false)

    }
  }
  const formatUSD = (value) => {
    // Elimina caracteres no numéricos excepto el punto
    value = value.replace(/[^0-9.]/g, '');
    
    // Formatea el valor a USD con dos decimales
    const formattedValue = parseFloat(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    setAmount(formattedValue);
  }
  
  return (
    <div className='w-80'>
      <h3 className='subTitleStep my-5 '>¿Cuánto quieres enviar?</h3>
      <div className='border'>
        <InputAmount
          amount={amount}
          onChangeAmount={(e) => {
            //console.log(e);
            setErrorStep1('')
            const formattedValue = formatDollar(e);
            setAmount(formattedValue);
            setAmount_(formattedValue);
          }}
          flag={USA}
          trm={trm}
          equivalent={equivalent}
        />
      </div>
      <div className='equivalent h-9 flex justify-center items-center'>
        <p className='equivalentLabel'>Pesos a transferir</p>
      </div>
      <InputAmountSecundary
        flag={CO}
        equivalent={Math.floor(equivalent)}
      />
      
      <Rates
        dollars={amount}
        sendFee={
          
         parseFloat(getDigits(amount)*.16)
        }
        amountToSend={payedAmount}
        amountToTransfer={Math.floor(equivalent)}
      />
      {
        errorStep1 &&
        <p className="text-red-600 text-sm text-left my-2">{errorStep1}</p>
      }
      <Button
      label='Siguiente'
      onClick={() => {

        setErrorStep1('')
        if (!amount) {
          setErrorStep1('Debe escribir un monto')
          return
        } else if (parseFloat(getDigits(amount))> 1000) {
          setErrorStep1('El monto no debe ser mayor a $1000')
          return
        }
        currentStep === steps.length
          ? setComplete(true)
          : setCurrentStep((prev) => prev + 1);
      }}
      loading={loading}
      />
       
    </div>
  )
}

export default Step1