import React, {useState} from "react";
import "../css/success.css";
import CHECK from "../assets/check-icon.png";
import Button from "./Button";

const Suceess = ({ id = "", name = "", currentStep=null, setCurrentStep=() =>{}, steps=[], setSuccess=()=>{}, resetData=()=>{}}) => {
    const [complete, setComplete] = useState(false);

    return (
    <div className="w-80 flex items-center flex-col justify-center h-full">
      <h3 className="ref my-5 ">{`REF - ${id}`}</h3>
      <div className="my-3">

      <p className="successsText">¡Completado!</p>
      </div>
      <div className="my-7">
      <img src={CHECK} className="checkIcon" alt="icon" />

      </div>
      <div className="my-7">
      <p className="info">
        {` en 24h hábiles ${name} recibirá el dinero en su cuenta de banco`}
      </p>

      </div>

      <Button
        label="OK"
        onClick={() =>{
          /*   currentStep === steps.length
            ? setComplete(true)
            : setCurrentStep(1); */
            setCurrentStep(1);
            resetData()
            setSuccess(false)
        }
       
        }
      />
    </div>
  );
};

export default Suceess;
