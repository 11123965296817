import React from 'react'
import '../css/input.css'

const Input = ({ value = '', onChange = () => { }, placeholder = '', className = '', errorMessage = '', name = '', onBlur = () => { }, type='text' }) => {

    return (
        <>
            <input type={type} value={value} name={name} onBlur={onBlur} className={`payment-input ${errorMessage !== '' ? 'input-error' : ''}${className}`} placeholder={placeholder} onChange={e => onChange(e.target.value)}
            />
            {
                errorMessage !== ''
                    ?
                    <div className='w-full px-3'>
                        <p className="text-red-600 text-sm text-left">{errorMessage}</p>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default Input