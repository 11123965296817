export const documentTypesArray = [
    { id: 0, label: 'Seleccionar', value: '',         label2: "CC",
},
    {
        label: `CC - Cédula de ciudadanía`,
        label2: "CC",
        value: 0,
    },
    {
        label: `CE - Cédula de extranjería`,
        label2: "CE",
        value: 1,
    },
    {
        label: `TI - Tarjeta de identidad`,
        label2: "TI",
        value: 2,
    }
]
export const bankAccountTypeListThridPart = [
    { value: null, name: 'Seleccionar', label: 'Seleccionar' },
    { value: 0, name: 'AHORROS', label: 'Ahorro' },
    { value: 1, name: 'CORRIENTE', label: 'Corriente' }
  ] 