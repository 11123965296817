import numeral from "numeral";

export const formatCOPNumberInput = (number, commas = false) => {
    try {
        const valorNumerico = number.toString().replace(/[^\d]/g, "");
        const valorFormateado = valorNumerico.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return `$${valorFormateado}`;

    }
    catch (error) {
        console.log('error formatCOPNumber ', error);
        return "";
    }
}
export const formatDollar = (value) => {
       // Elimina caracteres no numéricos y no puntos
       const numericValue = value.replace(/[^0-9.]/g, '');
    
       // Divide el valor en parte entera y parte decimal
       const parts = numericValue.split('.');
       
       // Formatea la parte entera con comas para separar miles
       parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
       
       // Combina las partes y agrega el símbolo de dólar
       const formattedValue = '$' + (parts[1] ? parts.join('.') : parts[0]);
       
       return formattedValue;
    /* // Elimina todos los caracteres que no sean números o puntos
    input = input.replace(/[^\d.]/g, '');

    // Divide la cadena en parte entera y parte decimal
    const parts = input.split('.');

    // Si hay más de un punto decimal, toma solo el primero
    if (parts.length > 2) {
        parts.splice(2);
    }

    // Formatea la parte entera con comas para separar miles
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Si no hay parte decimal, agrega ".00"
    if (parts.length === 1) {
        parts.push('00');
    } else if (parts[1].length === 1) {
        // Si la parte decimal tiene solo un dígito, agrega un cero adicional
        parts[1] += '0';
    }

    // Une las partes y agrega el símbolo de dólar
    const formattedUSD = '$' + parts.join('.');

    return formattedUSD; */
}
export const formatCOPNumber = (numero, mostrarCentavos = false) => {
    // Asegurarse de que el número sea un valor numérico
    if (isNaN(numero)) {
        return "$0";
        //return "No es un número válido";
    }

    // Redondear el número a 2 decimales si se muestran centavos
    if (mostrarCentavos) {
        numero = Number(numero).toFixed(2);
    }

    // Convertir el número a una cadena
    const numeroStr = numero.toString();

    // Usar una expresión regular para agregar comas como separadores de miles
    const regex = /(\d)(?=(\d{3})+(?!\d))/g;
    const numeroFormateado = numeroStr.replace(regex, "$1,");

    // Agregar el símbolo de peso colombiano y, si es necesario, los centavos
    // const simbolo = mostrarCentavos ? " COP" : " COP";
    const simbolo = '$ ';
    return simbolo + numeroFormateado;
}

export const swapArrayElements = function (arr, indexA, indexB) {
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
};

export const isAPhone = (str) => {
    if (typeof str !== "string") {
        return false;
    }

    const numero = Number(str);

    if (isNaN(numero) || !Number.isInteger(numero)) {
        return false;
    }

    if (str.length < 5) {
        return false;
    }

    return true;
}

export const millisecondsToMinutesAndSeconds = async (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return { minutes, seconds }
    //return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}
export const FormatCreditCard = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
        return parts.join(' ');
    } else {
        return value;
    }
}
export const FormatExpirationDate = (numberString, old_expiration_date_value) => {
    try {
        if (!numberString) {
            return ""
        }
        return numberString.replace(
            /[^0-9]/g, '' // To allow only numbers
        ).replace(
            /^([2-9])$/g, '0$1' // To handle 3 > 03
        ).replace(
            /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
        ).replace(
            /^0{1,}/g, '0' // To handle 00 > 0
        ).replace(
            /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
        );

    } catch (error) {
        console.log("error on expiration date formating");
        console.log(error);
        return numberString;
    }
}
export const copyClipbord = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            console.log('Copiado al portapapeles');
        })
        .catch(err => {
            console.error('Error al copiar al portapapeles:', err)
        })
}

export const getNavInfo = () => {
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;
    if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
    }
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    if ((ix = fullVersion.indexOf(";")) != -1) fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1) fullVersion = fullVersion.substring(0, ix);
    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    return { browserName }
}
export const getParams = (url) => {
    new Proxy(new URLSearchParams(url), {
        get: (searchParams, prop) => searchParams.get(prop),
    })
};

export const formatPhoneNumber = (text) => {
    // let t = text.replace(/\D/g,""); //quitamos los caracteres que no sean digitos

    // t = t.substring(0, 11);// el maximo de digitos para el numero de telefono es 12
    // t = t.replace(/^(\d{3})/g, "("+t.substring(0,3)+")" ); //agregamos los parentesis. 
    // t = t.replace(/\)(\d{3})/g, ") "+t.substring(5,8)+"-" ); //agregamos un espacio y el primer guion

    let t = text.replace(/^(\d{3})(\d{3})(\d{}).*/, '($1) $2-$3'); //quitamos los caracteres que no sean digitos
    //console.log('number,', t)
    t = t.substring(0, 16);// el maximo de digitos para el numero de telefono es 12
    //t = t.substring(0, 14);// el maximo de digitos para el numero de telefono es 12
    t = t.replace(/^(\d{3})/g, "(" + t.substring(0, 3) + ")"); //agregamos los parentesis. 
    t = t.replace(/\)(\d{3})/g, ") " + t.substring(5, 8) + "-"); //agregamos un espacio y el primer guion

    return t
}
export const formatDocument = (num) => {
    try {
        var num = num.replace(/\./g, '');
        if (!isNaN(num)) {
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[\.]/, '');
            return num;
        }

        else {
            return "";
        }
    }
    catch (error) {
        return "";
    }
}

export const getDigitsRegister = (text, isDataNumeric = false) => {
    return isDataNumeric ? parseInt(text.toString().replace(/\D/g, "")) : text.toString().replace(/\D/g, "");
}

export function capitalizeSentence(sentence) {

    return sentence
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
}

export const validateName = (cadena = '') => {
    try {

        const palabras = cadena?.split(' ');
        // Comprobar si hay mas de dos palabraa
        if (palabras.length < 2) {
            return false;
        }
        // Comprobar si las palabras tienen al menos un carácter cada una
        if (palabras[0].length < 1 || palabras[1].length < 1) {
            return false;
        }

        return true;
    } catch (error) {
        console.log('Error validateName');
        return false
    }
}

export const getDigits = (text) => {
    return text.toString().replace(/\D/g, "");
}

export const formatMoneySplit = (amount, divide = ',') => {
    if (!amount) {
        return ''
    }
    return {
        part1: amount.toString().split(divide)[0],
        part2: amount.toString().split(divide)[1] || '',
    }

}
export const getCuotasArray = () => {
    let cuotas = [{ id: 1, label: '1 Cuota', value: '1' }]
    for (let index = 1; index < 36; index++) {
        cuotas[index] = { id: index + 1, label: `${index + 1} Cuotas`, value: index + 1 };
    }
    return cuotas
}