import React from 'react'

const ListItem = ({ title = '', subtitle = '', onClick = () => { }, iconRight = null, className = '', selectedItem= '', index=0 }) => {
  return (
    <div
      className={`flex items-center cursor-pointer px-4 py-2 text-xl  ${className}`}
      onClick={() => onClick()}
    >
      <p className={`${index === 0 ? 'text-gray-500': ""}`}>{title}</p>
    </div>
  )
}

export default ListItem