import React from 'react'
import '../css/input.css'
import { formatCOPNumber } from '../functions'
const InputAmount = ({ amount='0', onChangeAmount= (e)=>{}, flag=null, trm=0}) => {
  return (
    <div>

    <div className='inputAmount flex py-2 px-3 row justify-between bg-white	items-center'>
      <input
        type='text'
        className='bg-white h-12 outline-none text-lg'
        value={amount}
        onChange={(e) => {
          onChangeAmount(e?.target?.value)
        }}
        placeholder='$100'
      />
      {
        flag &&
        <img src={flag} className='flag' alt='usa'/>
      }
    </div>
      <p className='rate'>{`$1 USD = ${formatCOPNumber(trm)} COP`}</p>
    </div>
  )
}

export default InputAmount