import React, { useState } from "react";
import "../css/stepper.css";
import { TiTick } from "react-icons/ti";

const Stepper = ({ steps = ["1", "2", "3"], info = {}, currentStep = 1, setCurrentStep = () => { }, setSteps = () => { }, resetData=()=>{} }) => {
  const [complete, setComplete] = useState(false);
  //const [currentStep, setCurrentStep] = useState(1);

  return (
    <div className="flex flex-row justify-between">
      {steps?.map((step, index) => (
        <div
          key={index}
          className={`step-item ${currentStep === index + 1 && "active"}`}
        >
          <div className="step">
            {index + 1}
          </div>

        </div>
        /* <div
          key={index}
          className={`step-item ${currentStep === index + 1 && "active"} ${
            (index + 1 < currentStep || complete) && "complete"
          } `}
        >
          <div className="step">
            {index + 1 < currentStep || complete ? (
              <TiTick size={24} />
            ) : (
              index + 1
            )}
          </div>
          {/* <p className="text-gray-500">{step}</p> 
        </div> */
      ))}
      {/* {
        !complete &&
        <button
        //className="btn"
        onClick={() => {
          currentStep === steps.length
            ? setComplete(true)
            : setCurrentStep((prev) => prev + 1);
        }}
      >
        {currentStep === steps.length ? "Finish" : "Next"}
      </button>
      } */}
    </div>
  );
};

export default Stepper;
