import React, { useState, useEffect } from "react";
import Stepper from "../components/Stepper";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2_";
import "../css/home.css";
import { documentTypesArray, bankAccountTypeListThridPart } from "../constants";
import Button from "../components/Button";
import Step3 from "../components/Step3";
import axios from "axios";
import { getDigitsRegister } from "../functions";
import Suceess from "../components/Suceess";
import ToogleModal from "../components/ToogleModal";

const Home = (props) => {
  //
  const [amount_, setAmount_] = useState(0)
  const [cobru, setCobru] = useState({})
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(["1", "2", "3"]);
  const [documentTypes, setDocumentTypes] = useState(documentTypesArray);
  const [documentItem, setDocumentItem] = useState(documentTypesArray[1]);
  const [bankTypeItem, setBankTypeItem] = useState(
    bankAccountTypeListThridPart[1]
  );
  const [bankItem, setBankItem] = useState({
    value: "",
    label: "Escoge un banco",
  });
  const [formValues, setFormValues] = useState({
    name: "",
    docType: "",
    doc: "",
    email: "",
    phone: "",
    accountNumber:""
    //amount: ''
  });
  const [errorMessages, setErrorMessages] = useState({
    amount: "",
    name: "",
    docType: "",
    doc: "",
    email: "",
    phone: "",
    accountNumber: ""
    //amount: ''
  });
  const [bank, setBank] = useState({ value: "", label: "Seleccionar Banco" });
  const [equivalent_, setEquivalent_] = useState(0)
  const [formValuesCreditCard, setFormValuesCreditCard] = useState({
    dues: "",
    nameCard: "",
    date: "",
    cvv: "",
    numberCard: "",
    //amount: ''
  });
  const [creditCardCuotas, setCreditCardCuotas] = useState({ id: 1, label: '1 Cuota', value: '1' })
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [amountCobru, setAmountCobru] = useState(0)
  const [errorMessageThird, setErrorMessageThird] = useState('')
  const [thirdItem, setThirdItem] = useState(null)
  const [errorStep1, setErrorStep1] = useState('')
  const [showModal, setShowModal] = useState(true)
  const [errorModal, setErrorModal] = useState('')
  const [password, setPassword] = useState('')
  const [passwordEncrypt, setPasswordEncrypt] = useState('')
  useEffect(() => {
    if (password) {
      setPasswordEncrypt(encryptString(password, ''))
    }
  }, [password])
//console.log(passwordEncrypt);

  //thirdPart
    const makeTransaction = async () => {
        setLoading(true)
        setErrorMessageThird('')
        let payload = {
            "name": formValues.name,
            "payment": 'credit_card',
            "cc": formValues.doc ? getDigitsRegister(formValues.doc) : documentItem.value ? getDigitsRegister(documentItem.value) : '',
            "email": 'juan@cobru.co',
            //"phone": formValues.phone.replace(/\D/g, ""),
            "document_type": documentItem.label2,
            //payer_country_code: country ? `+${country?.indicative}` : ''
            
        }
        console.log('el cobru por: ', amountCobru);
        
       const cobruRes = await axios.post('/api/cobru', { amount: parseFloat(amountCobru)})
       
       //console.log('aca', cobruRes?.data?.data?.owner);

       if (cobruRes?.data.status == 201 && cobruRes?.data) {
            setCobru(cobruRes?.data)
            //hacer el pago con tarejta de credito
            payload.credit_card = formValuesCreditCard?.numberCard.replace(/\D/g, "")
            payload.expiration_date = formValuesCreditCard?.date
            payload.cvv = formValuesCreditCard?.cvv
            payload.dues = parseInt(creditCardCuotas.value)
            payload.phone= cobruRes?.data?.data?.owner
            console.log('payload', payload);
            const resPayload = await axios.post('/api/getDetailsPay', {
                cobruURL: cobruRes?.data?.data?.url, payload
            })
            if (resPayload?.data?.data?.error ) {
              setErrorMessageThird('No se pudo crear el pago con la TC')
                //alert('error')
                setLoading(false)
            } else {
                console.log('aca');
                const details = await axios.post('/api/getDetails', {cobruURL: cobruRes?.data?.data?.url})
                if (details?.data?.data?.state === 3) {
                    const details = {
                        account_holder_name: formValues?.name,
                        account_type: bankTypeItem?.value,
                        account_holder_document: formValues?.doc.toString().replace(/\D/g, ""),
                        //account_holder_document_type: type_document_account === 4 ? 1 : type_document_account,
                        account_holder_document_type: documentItem?.value,
                        account_number:formValues?.accountNumber.toString().replace(/\D/g, ""),
                        bank_name: bankItem?.value,
                        override: true,
                        amount: Math.floor(equivalent_),
                        latitude: '0',
                        longitude: '0',
                        description: cobruRes?.data?.data?.url || 'GIRU'
                    }
                    console.log('se manda en retiro: ', details);
                    const thirdpartyRes = await axios.post('/api/thirdparty', details)
                    console.log('retiro', thirdpartyRes?.data);
                    if (thirdpartyRes?.data?.data?.success) {
                        setThirdItem(thirdpartyRes?.data?.data)
                        setLoading(false)
                        setSuccess(true)

                    } else {
                        setErrorMessageThird(thirdpartyRes?.data?.data?.message || 'Error al crear el retiro')
                        setLoading(false)
                    }
                    
                } else {
                  setErrorMessageThird('Cobru no pagado')
                  setLoading(false)
                }
                //console.log('details',details?.data?.data);
            }
       }
       setLoading(false)

    }
    const resetData = () =>{
      setSuccess(false)
      setLoading(false)
      setAmount_(0)
      setCobru({})
      setCurrentStep(1)
      setSteps(["1", "2", "3"])
      setDocumentTypes(documentTypesArray)
      setDocumentItem(documentTypesArray[1])
      setBankTypeItem(bankAccountTypeListThridPart[1])
      setBankItem({
        value: "",
        label: "Escoge un banco",
      })
      setFormValues({
        name: "",
        docType: "",
        doc: "",
        email: "",
        phone: "",
        accountNumber:""
        //amount: ''
      })
      setErrorMessages({
        amount: "",
        name: "",
        docType: "",
        doc: "",
        email: "",
        phone: "",
        accountNumber: ""
        //amount: ''
      })
      setBank({ value: "", label: "Seleccionar Banco" })
      setEquivalent_(0)
      setFormValuesCreditCard({
        dues: "",
        nameCard: "",
        date: "",
        cvv: "",
        numberCard: "",
        //amount: ''
      })
      setCreditCardCuotas({ id: 1, label: '1 Cuota', value: '1' })  
      setAmountCobru(0)  
      setErrorMessageThird('')
      setThirdItem(null)
      setErrorStep1('')
      setShowModal(true)
      setErrorModal('')
      setPasswordEncrypt('')
      setPassword('')
    }

    const validate =async() => {
      try {
        setErrorModal('')
        const res = await axios.post('/api/password', { password })
        //console.log(res);
        if (res?.data?.status === 200) {
          setShowModal(false)
        } else {
          setShowModal(true)
        }
      } catch (error) {
        setShowModal(true)

      }
    }

    if (success) {
        return (
            <div className="flex items-center justify-center h-full">

                    <Suceess
                        name={formValues?.name}
                        id={thirdItem?.id.toString()|| ''}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        steps={steps}
                        setSuccess={setSuccess}
                        resetData={resetData}
                        setThirdItem={setThirdItem}
                        //thirdItem={thirdItem}
                    />
            </div>
        )
    }

    // Función para encriptar un string
    const encryptString = (text, secretKey) => {
      //const encrypted = CryptoJS.AES.encrypt(text, secretKey);
      //return encrypted.toString();
      return ''

    }
    
    // Función para desencriptar un string
    const decryptString = (encryptedText, secretKey) => {
      //const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
      //return decrypted.toString(CryptoJS.enc.Utf8);
      return ''
    }
   
    
  return (
    <div className="flex h-screen items-center justify-start flex-col w-full">
      <h3 className="title my-5">Envíos a Colombia</h3>
      <Stepper
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setSteps={setSteps}
        setSuccess={setSuccess}
        resetData={resetData}
      />

      {currentStep === 1 && (
        <div className="flex items-center justify-center">
          <Step1
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={steps}
            setEquivalent_={setEquivalent_}
            setAmount_={setAmount_}
            setAmountCobru={setAmountCobru}
            amountCobru={amountCobru}
            setErrorStep1={setErrorStep1}
            errorStep1={errorStep1}
          />
         

        </div>
      )}
      {currentStep === 2 && (
        <div className="flex items-center justify-center">
          <Step2
            documentItem={documentItem}
            documentTypes={documentTypes}
            setDocumentItem={setDocumentItem}
            setDocumentTypes={setDocumentTypes}
            formValues={formValues}
            setFormValues={setFormValues}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            setBank={setBank}
            bank={bank}
            bankAccountTypeListThridPart={bankAccountTypeListThridPart}
            bankItem={bankItem}
            setBankItem={setBankItem}
            setBankTypeItem={setBankTypeItem}
            bankTypeItem={bankTypeItem}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={steps}
            setEquivalent_={setEquivalent_}
            amountCobru={amountCobru}
          />
        </div>
      )}
      {
        currentStep === 3 &&(
         <Step3
            name={formValues?.name}
            doc={formValues?.doc}
            docType={documentItem}
            equivalent={Math.floor(equivalent_)}
            bankItem={bankItem}
            bankTypeItem={bankTypeItem}
            accountNumber={formValues?.accountNumber}
            formValuesCreditCard={formValuesCreditCard}
            setFormValuesCreditCard={setFormValuesCreditCard}
            setCreditCardCuotas={setCreditCardCuotas}
            creditCardCuotas={creditCardCuotas}
            amount={amount_}
            makeTransaction={makeTransaction}
            loading={loading}
            amountCobru={amountCobru}
            setErrorMessageThird={setErrorMessageThird}
            errorMessageThird={errorMessageThird}
            setThirdItem={setThirdItem}
         />   
        )
      }
    
      <ToogleModal
        setShowModal={setShowModal}
        showModal={showModal}
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        onValidate={()=> {validate()}}
        setPassword={setPassword}
        password={password}
        encryptString={encryptString}
        decryptString={decryptString}
      />

    </div>
  );
};

export default Home;
