import React from 'react'
import '../css/input.css'
import { formatCOPNumber, formatCOPNumberInput } from '../functions'
const InputAmountSecundary = ({ amount='$1.793.520', onChangeAmount= (e)=>{}, flag=null, equivalent=0}) => {
  return (
    <div>

    <div className='inputAmountSecundary flex py-2 px-3 row justify-between bg-transparent items-center'>
      <input
        disabled
        className='bg-transparent h-12 text-lg'
        value={formatCOPNumber(equivalent)}
        onChange={(e) => {
          onChangeAmount(e?.target?.value)
        }}

      />
      {
        flag &&
        <img src={flag} className='flag' alt='usa'/>
      }
    </div>
    </div>
  )
}

export default InputAmountSecundary