import React, { useState, useEffect } from "react";
import "../css/step.css";
import Input from "./Input";
import SelectModal from "./SelectModal";
import { capitalizeSentence, formatDocument, getDigits, validateName } from "../functions";
import axios from "axios";
import Button from "./Button";

const Step2 = ({
  setDocumentItem = () => {},
  documentItem = null,
  documentTypes = [],
  setDocumentTypes = () => {},
  formValues = {
    name: "",
    docType: "",
    doc: "",
    email: "",
    phone: "",
    accountNumber: ""
    //amount: ''
  },
  setFormValues = () => {},
  bank = null,
  setBank = () => {},
  bankAccountTypeListThridPart = [],
  bankItem = null,
  setBankItem = () => {},
  setBankTypeItem = () => {},
  bankTypeItem = null,
  currentStep = null,
  setCurrentStep = () => {},
  steps = [],
  setEquivalent_=()=>{},
  amountCobru=0
}) => {
  const [errorMessages, setErrorMessages] = useState({
    amount: "",
    name: "",
    docType: "",
    doc: "",
    email: "",
    phone: "",
    accountNumber: ''
    //amount: ''
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [complete, setComplete] = useState(false);

  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = async () => {
    try {
      const res = await axios.post("/api/getBankList");
      console.log(res.data.status === 200);
      let newArray = [];

      if (res.data.status === 200) {
        newArray.push({
          value: '',
          label: "Escoge un banco",
        });
        res.data.data.forEach((element) => {
          newArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setBankList(newArray);
      }
    } catch (error) {
      setErrorMessage("Hubo un problema obteniendo la lista de bancos");
    }
  };
  const validate = () => {

    if (!formValues?.name || !formValues?.doc || !formValues?.accountNumber || bankItem.value ==null ) {
      return true
    }
  }
  const validateInput = () => {
    try {

        const amountInput = () => {
            /* if (getDigitsRegister(amount) < cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: 'El monto minimo es de ' + formatCOPNumber(cobru?.min) })
                return false
            }
            if (getDigitsRegister(amount) > cobru?.max) {

                setErrorMessages({ ...errorMessages, amount: 'El monto maximo es de ' + formatCOPNumber(cobru?.max) })
                return false
            }
            if (getDigitsRegister(amount) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            } */
        }
        const nameInput = () => {
            if (!validateName(formValues?.name)) {
                setErrorMessages({ ...errorMessages, name: 'Ingresa un nombre válido' })
                return false
            } else {
                setErrorMessages({ ...errorMessages, name: '' })
                return true
            }
        }

        const docInput = () => {
            if (getDigits(formValues?.doc) < 6) {
                setErrorMessages({ ...errorMessages, doc: 'Ingrese un número de documento válido' })
                return false
            } else {
                setErrorMessages({ ...errorMessages, doc: '' })

                return true
            }
        }
        const accountNumber = () => {
            if (getDigits(formValues?.accountNumber) < 10) {
                setErrorMessages({ ...errorMessages, accountNumber: 'Ingrese un número de cuenta válido' })
                return false
            } else {
                setErrorMessages({ ...errorMessages, accountNumber: '' })

                return true
            }
        }
        const emailInput = () => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues?.email)) {
                setErrorMessages({ ...errorMessages, email: '¡Has ingresado una dirección de correo electrónico no válida!' })
                return false
            } else {
                setErrorMessages({ ...errorMessages, email: '' })
                return true
            }
        }
        const phoneInput = () => {
            if (formValues.phone.replace(/\D/g, "").length < 6) {
                setErrorMessages({ ...errorMessages, phone: 'Ingresa un número de celular válido' })
                return false
            } else {
                setErrorMessages({ ...errorMessages, phone: '' })
                return true
            }
        }
        return {
            amountInput,
            nameInput,
            docInput,
            emailInput,
            phoneInput,
            accountNumber,
            all: (withPhone = false) => {
                let validations = nameInput() && docInput() && emailInput() && phoneInput() && accountNumber()
                if (withPhone) {
                    validations = validations && amountInput()
                }
                return validations
            }
        }

    } catch (error) {
        console.log('error validate inputs', error);
    }
}


  return (
    <div className="w-80">
      <h3 className="subTitleStep my-5 ">¿Quién recibe?</h3>
      <Input
        placeholder="Nombre"
        name="name"
        errorMessage={errorMessages.name}
        value={formValues.name}
        onChange={(value) => setFormValues({ ...formValues, name: value })}
        onBlur={() => { validateInput().nameInput() }}
      />
      <div className="flex items-start w-full">
        <div className="w-2/5 mr-3">
          <SelectModal
            customLabelKey="label2"
            items={documentTypes}
            selectedItem={documentItem}
            setSelectItem={setDocumentItem}
          />
        </div>
        <div className="w-full">
          <Input
            name="doc"
            errorMessage={errorMessages.doc}
            value={formValues.doc}
            onChange={(e) => {
              setFormValues({ ...formValues, doc: formatDocument(e) });
            }}
            placeholder="Número de identificación"
            onBlur={() => {
              validateInput().docInput();
            }}
          />
        </div>
      </div>

      <SelectModal
        customLabelKey="label"
        items={bankList}
        selectedItem={bankItem}
        setSelectItem={setBankItem}
      />
      <div className="flex items-start w-full">
        <div className="w-1/2 mr-3">
          <SelectModal
            customLabelKey="label"
            items={bankAccountTypeListThridPart}
            selectedItem={bankTypeItem}
            setSelectItem={setBankTypeItem}
          />
        </div>
        <div className="w-full">
          <Input
            name="accountNumber"
            errorMessage={errorMessages.accountNumber}
            value={formValues.accountNumber}
            onChange={(e) => {
              setFormValues({ ...formValues, accountNumber: e });
            }}
            placeholder="Número de cuenta"
            onBlur={() => {
              validateInput().accountNumber();
            }}
          />
        </div>
      </div>
      <div className="h-10"/>
      <Button
      className=""
        label="Siguiente"
        onClick={() => {
          if (validate()) {
            
          } else  {
            currentStep === steps.length
              ? setComplete(true)
              : setCurrentStep((prev) => prev + 1);
            console.log('format', formValues, bankItem);
          }
        }}
      />
    </div>
  );
};

export default Step2;
