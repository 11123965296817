import React from "react";
import "../css/step.css";
import PERSON from "../assets/person.png";
import { FormatCreditCard, FormatExpirationDate, formatCOPNumber, getCuotasArray } from "../functions";
import Input from "./Input";
import SelectModal from "./SelectModal";
import Button from "./Button";
const Step3 = ({
  equivalent = 0,
  name = "",
  accountNumber = "",
  bank = "",
  doc = "",
  docType = "",
  bankItem = null,
  bankTypeItem = null,
  setFormValuesCreditCard = () => {},
  formValuesCreditCard = {
    dues: "",
    nameCard: "",
    date: "",
    cvv: "",
    numberCard: "",
    //amount: ''
  },
  setCreditCardCuotas=() =>{},
  creditCardCuotas={ id: 1, label: '1 Cuota', value: '1' },
  loading=false,
  amount=0,
  amountCobru=0,
  makeTransaction= async () => {},
  errorMessageThird='',
  setErrorMessageThird=()=>{},
  setThirdItem=()=>{}
}) => {
  const bankName = (id) => {
    let banco = ''
    if (id == 0) {
        banco = 'Bancolombia'
    } else if (id == 1) {
        banco = 'Banco Bogota'
    } else if (id == 2) {
        banco = 'Davivienda'
    } else if (id == 3) {
        banco = 'BBVA'
    } else if (id == 4) {
        banco = 'Banco Occidente'
    } else if (id == 5) {
        banco = 'Colpatria'
    } else if (id == 6) {
        banco = 'Banco Popular'
    } else if (id == 7) {
        banco = 'Helm Bank'
    } else if (id == 8) {
        banco = 'Banco Agrario'
    } else if (id == 9) {
        banco = 'Banco BCSC'
    } else if (id == 10) {
        banco = 'Banco CorpBanca'
    } else if (id == 11) {
        banco = 'Banco Citibank'
    } else if (id == 12) {
        banco = 'Banco AV Villas'
    } else if (id == 13) {
        banco = 'Banco ProCredit'
    } else if (id == 14) {
        banco = 'Banco Pichincha'
    } else if (id == 15) {
        banco = 'Banco Bancoomeva'
    } else if (id == 16) {
        banco = 'Banco Santander'
    } else if (id == 17) {
        banco = 'Banco Falabella'
    } else if (id == 18) {
        banco = 'Nequi'
    } else if (id == 19) {
        banco = 'Banco DaviPlata'
    } else if (id == 20) {
        banco = 'Banco Finandina'
    } else if (id == 21) {
        banco = 'Banco Itaú'
    } else if (id == 22) {
        banco = 'Banco Itaú CorpBanca'
    } else if (id == 23) {
        banco = 'Lulo Bank'
    } else if (id == 25) {
        banco = "Ualá"
    } else if (id == 26) {
        banco = "Banco Cooperativo Coopcentral"
    } else if (id == 27) {
        banco = "Rappi pay"
    } else {
        banco = ""
    }
    return banco
}
const imgBank = (item) => {
    let image = ''

    if ((item) === ('Bancolombia') || bankName(item) === ('Bancolombia')) image = require("../assets/payment_methods/bancolombia.png");
    if ((item) === ('Depósito') || bankName(item) === ('Depósito') || (item) === ('Deposito')) image = require("../assets/payment_methods/powwi.png");
    if ((item) === ('Banco Bogota') || bankName(item) === ('Banco Bogota')) image = require("../assets/payment_methods/bancobogota.jpg");
    if ((item) === ('Davivienda') || bankName(item) === ('Davivienda')) image = require("../assets/payment_methods/bancodavivienda.png");
    if ((item) === ('BBVA') || bankName(item) === ('BBVA')) image = require("../assets/payment_methods/bbva.png");
    if ((item) === ('Banco Occidente') || bankName(item) === ('Banco Occidente')) image = require("../assets/payment_methods/bancooccidente.png");
    if ((item) === ('Colpatria') || bankName(item) === ('Colpatria')) image = require("../assets/payment_methods/colpatria.png");
    if ((item) === ('Banco Popular') || bankName(item) === ('Banco Popular')) image = require("../assets/payment_methods/bancopopular.png");
    if ((item) === ('Helm Bank') || bankName(item) === ('Helm Bank')) image = require("../assets/payment_methods/bancohelm.png");
    if ((item) === ('Banco Agrario') || bankName(item) === ('Banco Agrario')) image = require("../assets/payment_methods/bancoagrario.png");
    if ((item) === ('Banco BCSC') || bankName(item) === ('Banco BCSC')) image = require("../assets/payment_methods/bancocajasocial.png");
    if ((item) === ('Banco CorpBanca') || bankName(item) === ('Banco CorpBanca')) image = require("../assets/payment_methods/corpbanca.png");
    if ((item) === ('Banco Citibank') || bankName(item) === ('Banco Citibank')) image = require("../assets/payment_methods/citibank.jpg");
    if ((item) === ('Avvillas') || bankName(item) === ('Banco AV Villas')) image = require("../assets/payment_methods/bancoavvillas.png");
    if ((item) === ('Banco ProCredit') || bankName(item) === ('Banco ProCredit')) image = require("../assets/payment_methods/bancoprocredit.png");
    if ((item) === ('Banco Pichincha') || bankName(item) === ('Banco Pichincha')) image = require("../assets/payment_methods/bancopichincha.png");
    if ((item) === ('Banco Bancoomeva') || bankName(item) === ('Banco Bancoomeva')) image = require("../assets/payment_methods/bancoomeva.jpg");
    if ((item) === ('Banco Santander') || bankName(item) === ('Banco Santander')) image = require("../assets/payment_methods/bancosantander.png");
    if ((item) === ('Banco Falabella') || bankName(item) === ('Banco Falabella')) image = require("../assets/payment_methods/bancofalabella.png");
    if ((item) === ('Nequi') || bankName(item) === ('Nequi')) image = require("../assets/payment_methods/nequi.png");
    if ((item) === ('Banco DaviPlata') || bankName(item) === ('Banco DaviPlata')) image = require("../assets/payment_methods/bancodaviplata.png");
    if ((item) === ('Banco Finandina') || bankName(item) === ('Banco Finandina')) image = require("../assets/payment_methods/bancofinandina.png");
    if ((item) === ('Banco Itaú') || bankName(item) === ('Banco Itaú')) image = require("../assets/payment_methods/itau.png");
    if ((item) === ('Banco Itaú CorpBanca') || bankName(item) === ('Banco Itaú CorpBanca')) image = require("../assets/payment_methods/corpbanca.png");
    if ((item) === ('deposito') || bankName(item) === ('deposito')) image = require("../assets/payment_methods/pagos-gde.png");
    if ((item) === ('Ualá') || bankName(item) === ('Ualá')) image = require("../assets/payment_methods/uala.png");
    if ((item) === ('Rappipay') || bankName(item) === ('Rappi pay')) image = require("../assets/payment_methods/rappiPay.png");
    if ((item) === ('Banco Cooperativo Coopcentral') || bankName(item) === ('Banco Cooperativo Coopcentral')) image = require("../assets/payment_methods/coopcentral.png");

    return (image)
}
    let cuotas = getCuotasArray()
    
  return (
    <div className="w-80">
      <h3 className="subTitleStep my-5 ">Pagar</h3>
      {/**card*/}
      <div className="infoCard h-52 flex flex-col items-center justify-center">
        <div className="my-3">
          <p className="infoCardLabel"> Pesos e transferir</p>
          <p className="infoCardEquivalent">{formatCOPNumber(equivalent)}</p>
        </div>
        <div className="mx-10 w-full infoCardLine w-60" />
        <div className="flex flex-row items-center px-10 w-full mt-2">
          <img src={PERSON} className="infoCardImg mr-5" alt="person" />
          <div>
            <p className="infoCardName">{name}</p>
            <p className="equivalentLabel2">{`${docType?.label2} ${doc}`}</p>
          </div>
        </div>
        <div className="flex flex-row items-center px-10 w-full mt-2">
          <img src={imgBank(bankItem?.value)} className="infoCardImg mr-5" alt="person" />
          <div>
            <p className="equivalentLabel2">{bankTypeItem?.label}</p>
            <p className="equivalentLabel2">{`${accountNumber}`}</p>
          </div>
        </div>
      </div>
      <h3 className="subTitleStep my-5 ">Datos de pago</h3>
      <>
        <Input
          //type="number"
          name="numberCard"
          placeholder="Número de la tarjeta"
          value={formValuesCreditCard.numberCard}
          onChange={(value) => {
            setFormValuesCreditCard({ ...formValuesCreditCard, numberCard: FormatCreditCard(value)});
          }}
        />
        <div className="flex flex-row ">
          <div className="w-2/3">
            <Input
              /* type="number" */
              className="payment-input"
              placeholder="MM/AA"
              name="date"
              value={formValuesCreditCard.date}
              onChange={(event) => {
                const inputValue = event;
                //console.log(inputValue);
                setFormValuesCreditCard({...formValuesCreditCard,date: FormatExpirationDate(inputValue)});
              }}
            />
          </div>
          <div className="mr-3" />
          <div className="w-2/3">
            <input
              /* type="number" */
              className="payment-input"
              maxLength={"3"}
              pattern="[0-9]*"
              placeholder="CVV"
              value={formValuesCreditCard.cvv}
              onChange={(e) => {
                const inputValue = e.target.value;
                const cleanedValue = inputValue.replace(/\D/g, "");

                if (inputValue.length < 4) {
                    setFormValuesCreditCard({...formValuesCreditCard, cvv:inputValue});
                }
              }}
            />
          </div>
         {/*  <div className="mr-3" /> */}
        </div>
        <Input
          name="nameCard"
          placeholder="Nombre como aparece en la tarjeta"
          value={formValuesCreditCard.nameCard}
          onChange={(value) => {
            setFormValuesCreditCard({ ...formValuesCreditCard, nameCard: value});
          }}
        />
          {/* <SelectModal
            items={cuotas}
            selectedItem={creditCardCuotas}
            setSelectItem={setCreditCardCuotas}
          /> */}
      </>
      {errorMessageThird&&
      
      <p className="text-red-600 text-sm text-left my-2">{errorMessageThird}</p>
      }
      <Button
      disabled={loading}
      loading={loading}
        label={`Pagar ${amount}`}
        onClick={async() => {
            makeTransaction()
        }}
      />
    </div>
  );
};

export default Step3;
